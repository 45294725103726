.container {
  max-width: 300px;
  background-color: #eee;
  border-radius: 6px;
  text-align: center;
  padding: 10px;
}

.icon-container {
  padding-bottom: 10px;
  // The height of the image so that the content doesn't shift as the image loads
  height: 51px;
}

.title {
  font-weight: bolder;
}

.retry-button {
  display: block;
  margin: 0 auto;
}
