/* Overrides to bootstrap or font-awesome variables */
$fa-font-path: '~font-awesome/fonts/';
$font-size-sm: 12px;
$font-size-base: 13px;
$font-size-form-control: 14px;
$font-family-sans-serif: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;

$dropdown-link-hover-color: #fff;

$dropdown-link-active-bg: #fff;
$progress-bg: #c4d3d6;
$grid-float-breakpoint: 0px;
$state-success-text: #72c02c;
$alert-success-text: #3c763d;
$state-danger-text: #f4473a;
$table-condensed-cell-padding: 2px 3px;
$breadcrumb-separator: '»';
$table-cell-padding: 7px;
