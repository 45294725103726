/* Re-usable HTML/CSS components, akin to bootstrap components */
.dl-striped, .dl-striped-reversed {
  > div > dt {
    padding: 3px 3px 0 3px;

  }

  > div > dd {
    padding: 0 3px 3px 3px;
  }
}

.dl-horizontal-indented > div > dd {
  padding-left: 10px;
  margin-bottom: 5px;
}

.breadcrumb > li + li:before {
  padding: 0 0 0 5px;
}

.fa-sm {
  font-size: 0.85em;
}

.fa-lg {
  font-size: 1.25em;
}

.fa-xlg {
  font-size: 1.5em;
}

.input-xs {
  height: 20px;
  line-height: 1.2;
  padding: 3px 5px;
  font-size: $font-size-sm;
}

.input-sm, .form-control-sm {
  font-size: $font-size-sm;
}

.form-control-sm {
  padding: 3px;
  height: auto;
}

// override the library style for SelectIdentity to not display a cross
.rbt-input.is-invalid {
  background: none !important;
}

#advancedSearch .rbt-input-multi, .rbt-input-wrapper {
  min-width: 100%;
}

.rbt-input-multi, .rbt-input-wrapper {
  max-width: 490px;
}

.rbt-input-hint-container {
  overflow: hidden;
}

.input-md {
  font-size: 13px;
}

.input-number-no-spin {
  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input.input-number-no-spin, .input-number-no-spin input {
  -moz-appearance: textfield;
}

// Override bootstrap positioning since our font size is smaller
input[type="radio"],
input[type="checkbox"] {
  margin: 2px 0 0;
}

.dropdown-menu > .active > a {
  &, &:hover, &:focus {
    font-weight: bold;
  }
}

// Improve the look of blue icons when they are hovered over in the dropdown
.dropdown-menu > li {
  & .sq-text-primary {
    @extend .sq-text-primary;
  }

  & .disabled {
    color: $sq-disabled-gray;
  }
}

.dropdown-in-modal {
  z-index: $zindex-modal + 1 !important;
}

.dropdown-menu-sm {
  min-width: 120px;
}

ul.unstyled-list, ol.unstyled-list {
  list-style-type: none;
  padding-left: 0px;
}

.input-div {
  border-radius: 3px;
  margin-bottom: 5px;
  max-height: 500px;
  min-height: 300px;
  overflow: scroll;
}

a.large-link {
  font-size: 15px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    text-decoration: none;
  }
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  opacity: 0.45;
}

.btn-danger-muted {
  @extend .btn-danger;
  opacity: 0.8;
}

.btn-skinny {
  padding-right: 7px;
  padding-left: 7px;
}

.btn-hover {
  &.fa, &.fc {
    padding-top: 8px;
  }
}

.btn-transparent {
  padding: 4px 4px;
  cursor: pointer;
  border-radius: 2px;
}

// Because disabled buttons can't have tooltips, the workaround is to give the button a wrapper with this class and the tooltip.
// See: https://github.com/angular-ui/bootstrap/issues/1025
.disabled-btn-wrapper {
  display: inline-block;
}

.btn-group > .disabled-btn-wrapper {
  position: relative;
  float: left;
}

.btn-group > .disabled-btn-wrapper:first-child:not(:last-child) .btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.btn-group > .disabled-btn-wrapper:last-child:not(:first-child) .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.btn-group .btn + .disabled-btn-wrapper,
.btn-group .disabled-btn-wrapper + .disabled-btn-wrapper,
.btn-group .disabled-btn-wrapper + .btn {
  margin-left: -1px;
}

.table td .btn-xs {
  padding-top: 0;
  padding-bottom: 0;
}

.table .btn-group {
  display: flex; // Keeps the buttons from wrapping when in a small table cell
}

.btn-square-right {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.table-md-condensed > thead > tr > td,
.table-md-condensed > tbody > tr > td {
  padding: 3px 4px;
}

.table-condensed > tbody > tr > td.td-btn {
  padding-top: 1px;
  padding-bottom: 1px;
}

.table > tbody > tr > td.td-condensed-btn {
  padding: 0;
  vertical-align: middle;
}

.alert-condensed {
  padding: 4px 7px;
}

.table-fixed {
  table-layout: fixed;
}

.table-no-border tbody td {
  border: none;
}

.tableBuilderTextInput {
  min-width: 50px !important;
  max-width: 200px !important;
  min-height: 18px !important;
  padding-left: 5px;
  padding-right: 5px;
}

#filterThresholdColor {
  border-radius: 0;
  border-width: 0;
  min-width: 15px;
  max-width: 15px;
  height: 15px;
  border-color: '#fff';
  cursor: default !important;
  display: inline-block;
  align-items: center;
}

.tableBuilderFilterThresholdLine {
  border-bottom: 1px solid $sq-dark-gray
}

.tableBuilderHelpText {
  align-content: center;
  text-align: center;

  .coloredReport, .coloredAnalysis {
    font-size: 15px;
  }
}

.densityPlotLegend {
  display: inline-block;
  width: fit-content;
}

#scatterPlotFlipXY {
  margin-left: -3px;
  font-size: 15px;
  padding-left: 2px;
  padding-right: 2px;
  border-width: 1px 1px 1px 0;
  border-style: solid;
}

.scatterPlotCapsulePropertiesDropdown .btn {
  width: 100%;
  text-align: left;
}

.scatterPlotLegend {
  max-height: 100px;
}

.densityPlotNumBinInput .form-control-sm {
  height: min-content !important;
  padding: 1px !important
}

.toolbarPlotOptions {
  padding: 0 10px;

  &:not(.disabled) {
    &:hover {
      background-color: #007960;
      color: #ffffff;

      i {
        color: #ffffff;
      }
    }
  }
}

.minimapWrapper {
  height: 46px;
}

.minimapHandleLane {
  height: 6px;
  display: inline;
}

.minimapHandle {
  top: -6px;
  z-index: 100;
}

.minimapHandleDragging {
  position: relative;
  display: inline;
  top: -6px;
}

.minimapDragLayer {
  display: inline;
  height: 0px;
}

.minimap {
  height: 35px;
}

.dividerBar {
  border-width: 0 0 0 1px;
  width: 1px;
}

.toggleVisibilityBar {
  @extend .dividerBar;
  border-width: 0 1px;
  width: 11px;
  min-width: 11px;
  cursor: pointer;
  font-weight: bold;
}

.functionParamsSearchResults {
  max-height: 50vh;

  .flexFillOverflow {
    flex-basis: auto; // Workaround to make IE give flex elements height in a modal
  }
}

sq-threshold-metric {
  .colorSwatch {
    outline-offset: -3px
  }
}

ul.searchBreadcrumbs {
  li {
    @extend .sq-text-primary;
    @extend .cursorPointer;
  }

  li:last-child {
    @extend .cursorDefault;
  }

  li:before {
    content: '»';
    padding: 2px;
  }

  li:first-child:before {
    content: '';
    padding: 0px;
  }
}

ul.headerBreadcrumbs {
  white-space: nowrap;

  li {
    max-width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
    @extend .text-white;
    @extend .cursorPointer;

    a {
      @extend .text-white;
    }
  }

  li:before {
    content: '»';
  }

  li:first-child:before {
    content: '';
  }
}

.browseColumn {
  width: 340px;
  min-width: 340px;

  transition: width 0.5s ease;

  padding: 0;

  &.ng-hide {
    width: 0;
  }

  & > .flexRowContainer {
    &, & .journalOverview {
      padding: 12px 7px 0 7px;
    }
  }

  & > .journalPanel.flexRowContainer {
    padding: 0;
  }

  .panelTabs {
    a {
      &.active {
        background-color: $sq-light-gray;
        border-color: $sq-light-gray;
      }

      &:hover {
        background-color: $sq-overlay-gray;
      }

      &.disabled {
        background-color: $sq-disabled-gray !important;
        cursor: not-allowed !important;
        pointer-events: auto !important;
      }

      i {
        float: left;
      }

      background-color: $sq-darker-gray;
      border-color: $sq-dark-gray;
      color: $black !important;
      height: 35px;
    }
  }

  sq-annotations-badge {
    &.tab-icon {
      top: 4px;
      left: 5px;
    }

    .fc {
      margin-top: 1px;
      font-size: 19px;
    }

    small {
      font-size: 8px;
    }
  }

  .icon {
    align-self: center;
  }

  .toolbar {
    padding: 0 5px 10px 5px;
    border: 0px;

    .option.btn.active {
      border-width: 2px;
      box-shadow: none;
    }

    .icon {
      height: 20px;
      width: 20px;
    }
  }

  .option {
    padding: 0px;
    width: 40px;
    height: 40px;
    margin: 0 5px;
  }

  .toolOverviewCard {
    border-radius: 3px;
    margin: 0 5px 5px 2px;
    padding: 7px 7px 10px 12px;

    &:hover, &:focus {
      background: white;
      outline: 0;
    }

    .headerName {
      font-size: 16px;
    }

    .tooltipIcon {
      font-weight: bold;
    }
  }

  .nav-tabs.nav-justified > li {
    display: table-cell;
    width: 1%;

    & > a {
      padding: 8px 10px;
      border-width: 1px;
      border-bottom-width: 0px;
      margin-bottom: 0;
      text-decoration: none;
    }

    &.active {
      & > a {
        &, &:hover, &:focus {
          border-width: 0;
          outline: 0;
        }
      }
    }

    &:not(.disabled):not(.active) {
      & > a {
        &:hover, &:focus {
          outline: 0;
        }
      }
    }
  }

  .badge {
    font-size: 11px;
  }

  .card {
    border-width: 0;
    margin: 3px;

    h4, .h4 {
      font-size: 16px;
    }

    sq-check-mark-or-number {
      .fa-check, .fa-plus-square, .fa-minus-square {
        font-size: 16px;
      }
    }

    .card-header {
      border-width: 0;

      h4, .h4 {
        padding-top: 10px;
      }
    }
  }

  .card-header {
    color: inherit;
  }
}

.dateTimeEntryField {
  border-radius: 2.5px;
  padding: 5px;
  font-size: 15px;
}

.multiSelectTwoLine {
  .form-control, .react-select__value-container {
    height: auto;
    min-height: 34px;
    max-height: 50px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    flex-wrap: wrap !important;
  }

  z-index: 101;
}

.multiSelectTwoLineModal {
  .form-control, .react-select__value-container {
    height: auto;
    min-height: 34px;
    max-height: 80px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    flex-wrap: wrap !important;
  }

  z-index: 101;
}

.annotationCard {
  &.card-default, &.card-default .card-header, &.card-default .card-body {
    transition: background-color 500ms ease-in;
  }

  .commentDate::before {
    padding-left: 3px;
    padding-right: 3px;
    content: "\00b7";
  }

  .commentActions {
    align-self: flex-end;
  }

  .commentName p {
    margin-top: 5px;
    margin-bottom: 4px;
  }

  .commentNameEdit {
    margin-top: 6px;

    textarea {
      margin-right: 6px;
      margin-bottom: 3px;
    }
  }

  &.reply {
    margin-left: 8px !important;
    box-shadow: 0 0 0 $white !important;
    border: 0;
  }
}

.toolbar {
  border-width: 1px 0;
  padding: 4px 10px;

  .btn-group:not(.dropdown) {
    padding-right: 6px;
    margin-right: 6px;

    &:last-of-type {
      border-right-width: 0;
    }
  }

  .btn-toolbar {
    border-width: 0;
    background-color: transparent;

    &.active, &:hover {
      opacity: 1;
    }

    &.btn.disabled, &.btn[disabled] {
      opacity: 0.45;
    }

    small {
      display: block;
      font-size: 10px;
      line-height: 10px;
      max-width: 50px;
      white-space: normal;
    }
  }

  .sq-btn {
    padding: 4px 8px;
    height: auto;
  }

  .sq-btn-toolbar-dropdown .sq-btn {
    height: auto;
  }

  .sq-btn-toolbar-sm {
    padding: 3px 5px;
  }
}

#assetGroupBtns {
  display: inline-flex !important;
}

.toolbar-sm {
  padding: 0;
  border-top-width: 0;

  .btn-toolbar {
    margin: 1px 0;
  }

  .btn-xs {
    padding-top: 0;
    padding-bottom: 0;
  }

  .fa.small {
    font-size: 70%;
  }
}

#trendViewerToolbar {
  height: 50px; // Needed because btn elements inside are float so they take up no vertical space in Chrome
}

.labelsPopover {
  max-width: 100%;

  // The width and height are specified for a smooth transition
  .customLabels {
    &, & > div {
      height: 288px;
    }

    & > div {
      width: 221px;

      .in {
        overflow-x: hidden;
      }
    }

    input {
      width: 100%;
    }
  }
}

.optionHeader {
  border-radius: 3px;
  padding: 5px;
}

// Prevent buttons from wrapping while animating the close of the panel
.infoPanel .toolbar-sm {
  white-space: nowrap;
  overflow: hidden;
}

.infoPanel {
  input.input-sm, input.form-control-sm {
    padding: 5px;
  }

  // shrinks the dropdown arrow on IE
  select.input-sm, select.form-control-sm {
    padding: 0 0 0 2px;

    &::-ms-expand {
      width: 0.9em;
    }
  }
}

.btn-lg-text {
  padding: 4px 8px;

  .fa {
    font-size: 15px;

    &.small {
      font-size: 9px;
    }
  }
}

// Because we want to use percentages for the height of child elements the
// parent needs a real height so we use this "workaround" of absolute
// positioning to get it one.
.resizablePanelWrapper {
  position: relative;
}

.resizablePanelContainer {
  position: absolute;
  height: 100%;
  width: 100%;
}

sq-date-time-entry, .durationTimeEntry {
  input::-ms-clear {
    width: 0;
    height: 0;
  }
}

.fixedWidthAddOn {
  min-width: 25px;
}

.searchTitleDiv {
  overflow-x: hidden;
  border-radius: 4px;
  padding: 6px 10px 6px 15px;
}

.searchTitleInput {
  & input, & .input-group-btn {
    box-shadow: none;
  }

  & input {
    font-size: 16px;
    line-height: 16px;
    background-color: none;

    &:focus, &:hover {
      outline: 0;
    }
  }
}

.investigateRangeSelector > div:first-child {
  padding: 4px 8px 3px 25px;
}

.investigateRangeSelector {
  #investigateRangeSelector {
    padding-right: 3px;
  }
}

.displayRangeSelector .durationTimeEntry:first-of-type span {
  @extend .text-right;
}

.seeqContentWrapper .displayRangeSelector {
  min-height: 21px;

  .displayRangeDateTime.displayRangeEnd {
    padding-left: 26px;
  }
}

.investigateRangeSelector, .displayRangeSelector {
  sq-date-time-entry, .dateTimeEntry, sq-duration-entry, .durationTimeEntry {

    & input {
      text-align: inherit;
    }
  }
}

.datasourcesPopup, .autoUpdatePopup {
  .arrow {
    position: absolute;
    border-color: transparent;
    border-style: solid;
    display: block;
    border-width: 10px;
    transform: translate(125px, 20px);
    border-top-color: rgba(0, 0, 0, 0.25);
    bottom: -20px;
    text-align: center;
    margin: 0;
    pointer-events: none;

    &::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: transparent;
    }

    &::after {
      content: " ";
      border-width: 10px;
      left: -10px;
      top: -11px;
      border-top-color: #fff;
    }
  }
}

.autoUpdatePopup {
  .popover-title {
    text-align: left;
    font-weight: unset;
  }

  .updateManualContainer label {
    flex-direction: row;
    display: flex;
    align-items: baseline;
  }

  .autoUpdateOptions {
    .form-check-label {
      font-weight: 600;
    }
  }
}

// Gets bootstrap 4 popover working alongside bootstrap 3
.bs-popover-bottom > .arrow {
  border-style: none;
}

.popover-header {
  border-bottom: none;
  font-size: inherit;
  font-weight: bolder;
  text-align: center;
}

.popoverLarge {
  min-width: 550px;
}

.noOutline {
  &:hover, &:focus {
    outline: 0px;
  }
}

.requestDetailsProgressWidth {
  width: 450px
}

.requestDetailsTitle {
  font-size: larger;
}

.requestDetailsLegendSwatch {
  width: 12px;
  height: 12px;
}

.bottomPanel {

  & > * {
    overflow: auto;
    // This causes the content to overflow when the contents cannot fit into the panels space anymore
    max-width: 100%;
  }

  & > .detailsPanel {
    flex: 1 0 250px;
    transition: all 0.07s ease-in-out 0s;

    .icon {
      padding: 0px;
    }

    .groupingButton:hover {
      i {
        color: $white !important;
      }

    }
  }

  .divider {
    min-width: 6px;
    max-width: 6px;
    border: none;
    overflow: hidden;
    cursor: ew-resize;

    i {
      font-size: 8px;
    }
  }

  .header {
    padding: 0 5px;
    font-weight: normal;
    white-space: nowrap;
    height: 26px;
  }
}

/* worksheets */
.worksheetContainer {
  position: relative;
}

.activeWorksheetIndicator {
  height: 80px;
  width: 10px;
  position: absolute;
}

.dimmedImage {
  opacity: 0.2;
}

.worksheetEditingOverlay {
  position: absolute;
  top: 32px;
  right: 20px;
  opacity: 0.3;
}

.activeWorkbookIndicator {
  float: left;
  height: 80px;
  width: 10px;
}

.worksheetWell {
  margin-bottom: 2px;
}

.worksheetSlide {
  width: 100px;
  margin-left: 13px;
}

.worksheetScroll {
  position: relative;
  overflow: hidden;
}

.worksheetTabs {
  padding-right: 11px !important;
  padding-left: 11px !important;
}

sq-worksheet-thumbnail, sq-worksheet-thumbnail img {
  display: block;
  width: 100px;
  height: 80px;
  margin: 0 auto;
}

/* workbooks */
.workbookSlide {
  width: 210px;
  padding: 10px 10px 15px 10px;
  margin: 10px 20px 10px 0;
  border-left: 8px solid transparent;
  border-radius: 4px;
  position: relative;

  .workbookLabel {
    font-size: 16px;
    max-height: 43px;
    overflow: hidden;
  }

  sq-open-button {
    position: absolute;
    right: 10px;
  }

  &.text-center:hover {
    box-shadow: none;
  }

  .workbookLabel > span > * {
    outline: none;
  }
}

.workbooksList {
  padding: 10px 20px;
  flex-basis: 61.8%;

  .form-control {
    min-width: 100px;
    max-width: 350px;
  }
}

// Separate header label so we can have a focus event listener for the workbook name in header
.truncatedLabel, .headerLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.headerLabel:focus {
  text-overflow: clip;
}

.navbarHomeButton {
  float: left;
  padding: 12px;

  &:hover {
    cursor: pointer;
    transition: color 0.2s ease-in;
  }
}

.scrollableSearchModal {
  .searchBreadcrumbs {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .modal-body {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }
}

div.modal-body {
  ul.dropdown-menu {
    li.uib-typeahead-match {
      cursor: pointer;
    }

    li.uib-typeahead-match.active > a {
      &, &:hover, &:focus {
        font-weight: normal;
      }
    }
  }
}

.workbookAcl .dropdown-menu {
  width: 450px !important;
}

.workbookAclTooltip {
  th, td {
    text-align: left;
    vertical-align: top;
    padding: 2px;
  }
}

.sq-bg-trace:nth-child(even) {
  background: $backgroundcolor-trace1;
}

.sq-bg-trace:nth-child(odd) {
  background: $backgroundcolor-trace2;
}

.sq-bg-debug:nth-child(even) {
  background: $backgroundcolor-debug1;
}

.sq-bg-debug:nth-child(odd) {
  background: $backgroundcolor-debug2;
}

// Empty styling reserved to enable easy class assignment and reserving this as an un-modified class.
.sq-bg-info:nth-child(even) {
  background: $backgroundcolor-info1;
}

.sq-bg-info:nth-child(odd) {
  background: $backgroundcolor-info2;
}

.sq-bg-warn:nth-child(even) {
  background: $backgroundcolor-warn1;
}

.sq-bg-warn:nth-child(odd) {
  background: $backgroundcolor-warn2;
}

.sq-bg-error:nth-child(even) {
  background: $backgroundcolor-error1;
}

.sq-bg-error:nth-child(odd) {
  background: $backgroundcolor-error2;
}

.headerOptionsButton {
  padding: 12px;
}

.workstepButton {
  user-select: none;

  &:not([disabled=disabled]) {
    transition: color 0.2s ease-in;
  }

  &:hover:not([disabled=disabled]) {
    cursor: pointer;
    transition: color 0.2s ease-in;
  }

  &[disabled=disabled],
  :hover[disabled=disabled] {
    cursor: auto;
  }
}

/* Adjust location of spinner */
#loading-bar-spinner {
  position: absolute;
  color: $white;
  top: 19px !important;
  left: 122px !important;
}

sq-user-profile.overlayPanel {
  position: absolute;
  top: 47px;
  right: 0px;
  z-index: $zindex-dropdown;
  min-width: 370px;
  border-width: 0;

  h4 {
    font-size: 16px;
  }

  .form-group {
    margin-bottom: 25px;
  }

  .control-label {
    text-align: right;
  }
}

.overlayPanel {
  padding: 12px 20px 12px 20px;
  border-width: 0;

  form {
    #spinner {
      opacity: 1;
      transition: opacity linear 1s;
      transition-delay: 0.5s;

      &.ng-hide {
        opacity: 0;
        transition: opacity linear 0s;
      }

      position: relative;
      top: 9px;
      left: 5px;

      i {
        position: absolute;
      }
    }

    .form-group {
      margin-bottom: 15px;
    }

    .control-label {
      text-align: right;
    }

    .two-line-control-label {
      padding-top: 0px !important;
    }
  }

  #loginError {
    position: absolute;
    left: 40px;
    margin-top: 5px;
    opacity: 1;
    transition: opacity linear 0.2s;

    &.ng-hide {
      opacity: 0;
      transition: opacity linear 0s;
    }
  }
}

.loginPanel {
  width: 400px;
  padding-left: 0px;
  padding-right: 0px;
}

.loginBackdrop {
  background-image: url(/login-image);
  background-position: center top;
  background-size: cover;
}

.loginBtn {
  padding: 0 0 0 9px;
}

.danger-tooltip {
  .tooltip-inner {
    @extend .nowrap;
  }
}

.tooltip-text-left {
  .tooltip-inner {
    text-align: left;
  }
}

.worksheetViewSelector {
  height: fit-content;
  align-self: center;
  border-radius: 5px;

  .btn {
    padding: 8px 5px;
  }
}

// Workaround for CRAB-42458 where the browser can freeze when a complex SVG path is rendered beneath a popover because
// of a Chromium bug. Needs to be "fill" so that there are still some interactions like labels on hover.
.chartView,
.scatterPlot {
  pointer-events: fill;
}

.chartView {
  .highcharts-cursor-anchor {
    cursor: pointer;
  }

  .highcharts-cursor-x-label {
    text {
      stroke-width: 0;
    }

    stroke-width: 0.5;
    line-height: 20px;
  }

  .highcharts-hover-x-label {
    text {
      stroke-width: 0;
    }

    stroke-width: 0.5;
    line-height: 20px;
  }

  .highcharts-plot-band-label {
    white-space: normal !important;
  }

  .highcharts-data-labels .highcharts-label span span {
    padding-left: 2px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 11px;
  }

  .highcharts-cursor-capsule-label {
    height: auto;
    width: 120px;
    max-width: 120px;

    & span span {
      background: #fff;
      padding: 0 5px;
      border-width: 1px;
      border-style: solid;
      display: block;
      white-space: normal !important;
      line-height: 11px;
    }
  }
}


#regionSlider {
  svg {
    font: 10px sans-serif;
  }

  text {
    font: 12px sans-serif;
  }

  circle {
    -webkit-transition: fill-opacity 250ms linear;
  }

  .selecting circle {
    fill-opacity: .2;
  }

  .resize path {
    fill-opacity: 1;
    stroke-width: 1.5px;
  }

  .axis path, .axis line {
    fill: none;
    shape-rendering: crispEdges;
  }

  .brush .extent {
    shape-rendering: crispEdges;
  }

  .hide {
    display: none;
  }
}

option[value=""][disabled] {
  display: none;
}

#formulaContainer {
  #variableGroupWrapper-wrapper {
    overflow: hidden; // Ensures the parameter table has a scroll bar in IE
    min-height: 100px;
    max-height: 45%;

    td {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .formulaTextContainer {
    min-height: 110px;
  }

  .formulaHelp {
    width: 400px;
    min-width: 400px;
    padding-bottom: 50px;

    h1 {
      font-size: 16px;
    }

    h2 {
      font-size: 14px;
    }

    h3 {
      font-size: 12px;
    }

    pre {
      overflow: auto;
    }
  }
}

#formulaEditorToolbar {
  border-radius: 5px 5px 0px 0px;
  border-bottom: 0;
  z-index: 10;

  .toolbarDropdown {
    position: absolute;
    width: 300px;
  }
}

.administration {
  margin: 5px 20px;

  .tab-content {
    flex: 1 1 auto;
    overflow: hidden;
  }
}

.modal-body {
  padding: 15px;
}

.user-admin, .group-admin {
  margin-bottom: 12px;

  .modal-header {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-bottom: none;

    .modal-title {
      text-align: left;
      font-weight: bold;
      font-size: larger;
      margin-left: 5px;
    }
  }

  .modal-body {
    padding: 0px;

    form div.entry-field {
      padding-left: 0px;
      padding-right: 0px;

      > span {
        line-height: 32px;
        padding: 0 13px;
      }
    }
  }

  .control-label {
    text-align: left;
    font-weight: normal;
    padding: 0px;
  }

  .option {
    max-width: 200px;
    white-space: nowrap;
    font-weight: normal;
  }

  .optionNameWrapper {
    display: inline-block;
    min-width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }

  .userAndGroupList {
    height: 250px;
    overflow: scroll;

    ::selection {
      color: none;
      background: none;
    }
  }

  .noSelection {
    ::selection {
      color: none;
      background: none;
    }
  }
}

.group-admin {
  .control-label {
    margin-bottom: auto;
  }
}

.searchWidget {
  .heading {
    padding: 15px 0 3px 0;
    @extend .cursorPointer;
    @extend .sq-text-primary;
  }
}

.itemRow {
  &:hover, &:focus {
    outline: 0;
  }

  .searchBtn {
    margin-right: 3px;
    padding: 3px;

    &.fc-pin {
      @extend .sq-text-primary;
    }

    &:hover:not(.fa-spin), &:focus:not(.fa-spin) {
      @extend .sq-text-primary;
      border-radius: 2px;
      outline: 0;
    }
  }
}

.searchNavigation {
  padding: 5px;
}

.advancedFormWrapper {
  transition: max-height 0.35s ease;

  &.ng-animate {
    overflow: hidden;
  }

  // ng-if animation used as opposed to ng-show to avoid animation showing when the worksheet loads
  &.ng-leave, &.ng-enter.ng-enter-active {
    max-height: 300px;
  }

  &.ng-leave.ng-leave-active, &.ng-enter {
    max-height: 0;
  }
}

.colorPickerSwatch {
  width: 15px;
  height: 15px;
  border: 1px solid;
  cursor: pointer;

  &.hsSwatch {
    border: none;
    border-radius: 3px;
    width: 30px;
    height: 30px;
  }

  &.large {
    width: 45px;
    margin-bottom: 2px;
  }
}

#derivedDataPanel {
  margin-top: 10px;
}

.transitionMaxHeight {
  transition: max-height 0.4s ease;
}

.derivedDataPanelClosed {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.derivedDataPanelOpen {
  max-height: 250px;
}

.derivedDataTreeDisplay {
  margin: 0;
  padding: 0;
  list-style: none;

  .spacer {
    width: 22px;

    &.notLast {
      border-left: 1px solid lightgrey;
    }

    &:first-child {
      width: 9px;
      border-left: none;
    }
  }

  .notLastChild {
    width: 2px;
    height: 19px;
    margin-right: -1px;
    border-left: 1px solid lightgrey;
  }

  .isChild {
    width: 10px;
    height: 0.7em;
    border-bottom: 1px solid lightgrey;

    &.isLast {
      width: 11px;
      border-left: 1px solid lightgrey;
    }
  }

  .itemIcon:before {
    vertical-align: middle;
  }
}

.animate-if-opacity.ng-enter, .animate-if-opacity.ng-leave {
  transition: opacity 0.5s linear;
}

.animate-if-opacity.ng-enter,
.animate-if-opacity.ng-leave.ng-leave-active {
  opacity: 0;
}

.animate-if-opacity.ng-leave,
.animate-if-opacity.ng-enter.ng-enter-active {
  opacity: 1;
}

.file-drop-zone {
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.unstyled-input {
  appearance: none;
  border: none;
  background: transparent;

  &:focus {
    outline: none;
  }

  // Select styles from the .form-control class
  font-size: $font-size-base;
  line-height: $line-height-base;
}

// IE places an (X) to the right of input fields. This is undesirable for unstyled-input
input[type=text].unstyled-input::-ms-clear {
  display: none;
}

.toolsSearchBar {
  margin-bottom: 1px;

  &.active {
    margin-bottom: 0px;
  }
}

.itemAsset {
  color: $sq-fairly-dark-gray;
  font-size: 11px;
  margin-top: -2px;
}

/* Specify styling for tooltips that specify tooltip-class="warningTooltip" */
.tooltip.warningTooltip {
  /* Hide arrow */
  .tooltip-arrow {
    display: none;
  }
}

.autoUpdate.btn-group {
  display: flex; // prevent wrapping
  label.compact {
    font-size: 11px;
    padding: 0 2px;
  }
}

.autoUpdatePopup {
  .header {
    padding: 5px;
    margin: -10px -15px 10px -15px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    .title {
      margin-top: 3px;
      margin-left: 4px;
    }

    .close {
      margin-right: 4px;
    }
  }

  .radioButton {
    margin-bottom: 8px;
  }

  select {
    min-width: 60px;
    // hides the dropdown arrow
    &::-ms-expand {
      display: none;
    }
  }
}

sq-license-expiration-warning {

  font-size: larger;

  a {
    text-decoration: underline;
  }
}

.license-management {
  font-size: 16px;
  overflow-y: auto;

  .section {
    width: 700px;
  }

  .title {
    font-size: 20px;
    font-weight: normal;
  }

  .inset {
    padding: 0 50px 0 50px;
  }

  .messageArea {
    height: 350px;
  }

  .file-drop-zone, .file-drop-zone-uploaded, .license-email-content {
    width: 100%;
  }

  .license-email-button {
    width: 200px;
  }

  .license-header {
    padding: 30px 0 20px 0;
    font-weight: bold;
  }
}

label {
  font-weight: 600;
}

dt {
  font-weight: 600;
}

th {
  font-weight: 600;
}

.semi-bold {
  font-weight: 600;
}

#detailsPanelContent {
  .btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    opacity: 1;
  }

  .table td .btn-xs, .table td .btn-group-xs > .btn {
    padding-top: 3px;
  }

  .caret {
    position: absolute;
    right: 7px;
    top: 9px
  }

  .columnsBtn {
    padding-top: 2px;
    padding-bottom: 0px;
  }
}

.scrollable-menu {
  height: auto;
  overflow-x: hidden;
  -ms-overflow-style: scrollbar;
}

.treemapPage .breadcrumb li.pull-right:before {
  content: '';
}

.scatterPlotToolbar,
sq-treemap-wrapper {
  .react-select__control,
  .react-select__control:hover,
  .react-select__indicators {
    min-height: 20px !important;
  }

  .react-select__value-container {
    padding: 0 5px;
  }

  .react-select__indicator {
    padding: 0 !important;
  }
}

sq-treemap-wrapper {
  .highcharts-label {
    opacity: 1 !important;
    font-weight: normal !important;

    h4 {
      margin-top: 0;
    }

    // Override highcharts styles so that statistic labels wrap when the node is small
    & > span {
      position: relative !important;
      display: block;
      white-space: normal !important;
      top: 0 !important;
      left: 0 !important;
    }

    .label-container {
      word-break: break-word;
      margin: 10px 5px 10px 15px;
    }

    .label-details-container {
      overflow: hidden;
    }

    .statistic-info {
      margin-bottom: 5px;
      font-size: 13px;
      font-weight: normal !important;
    }

    .statistic-details {
      padding-left: 10px;
    }
  }
}

.treemapContainer {
  .highcharts-data-labels {
    right: 25px;
  }

  .highcharts-point {
    stroke: #fff;

    &.highcharts-point-hover {
      opacity: 0.85;
      border: 1px solid #ddd;
    }
  }
}

.toolOptions:not(.advancedPanel):not(.advancedFilterPopover) {
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;

  &, .divider, .alert {
    margin-left: -4px;
    margin-right: -4px;
  }

  .alert {
    border-radius: 0;
  }
}

.advancedPanel, .advancedPanelNoMargin {
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;

  .divider {
    margin-left: -4px;
    margin-right: -4px;
  }

  .formElement {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.advancedPanel {
  margin-left: 10px;
  margin-right: 10px;
}

.advancedFilterPopover {
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  max-width: 220px;

  .divider {
    margin-left: -4px;
    margin-right: -4px;
  }
}

.table-container {
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
}

.table-legend {
  overflow-y: auto;
}

.advancedHeader {
  @extend .sq-text-primary;
  @extend .cursorPointer;
}

.resizablePanel {
  z-index: $zindex-dropdown;
  display: flex;

  .handle-right {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .handle-bottom {
    border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .rg-right, .rg-bottom {
    display: none;
  }

  .showResizeablePanel {
    z-index: $zindex-dropdown;
    position: absolute;
    border-radius: 4px;
    padding-top: 0;
    padding-left: 0;
    padding-right: 14px;
    padding-bottom: 14px;

    .rg-right {
      display: flex;
    }

    .rg-bottom {
      display: block;
    }

    z-index: $zindex-dropdown;
  }

  .rg-right {
    align-items: center;

    i {
      margin-left: 2px;
    }

    margin-right: 0px;
    cursor: ew-resize;
  }

  .rg-bottom {
    text-align: center;
    margin-bottom: 0px;
    cursor: ns-resize;
    width: calc(100% - 14px);

    i {
      transform: rotate(90deg);
    }
  }
}

body:not(.runningInProtractor) {
  .animateHeight250Linear {
    transition: height 0.25s linear;
  }

  .animateHeight125Linear {
    transition: height 0.125s linear;
  }

  .animateWidth250Linear {
    transition: width 0.25s linear, max-width 0.25s linear;
  }
}

.msOverflowStyleAuto {
  // Tell IE 11 to display scroll bars when necessary
  -ms-overflow-style: auto;
}

.textAreaResizeNone {
  resize: none;
}

.disabled {
  &, &:hover, &:active, &:focus {
    cursor: default;
  }
}

.backgroundColorInherit {
  background-color: inherit;
}

.showOnTop {
  z-index: 9999;
}

.instructions {
  font-style: italic;
  font-size: 80%;
  padding: 10px 5px 5px 5px;
}

.dataStatusIcon {
  position: relative;
  display: inline-block;
  width: 19px;
  height: 19px;
  text-align: center;
}

.dataStatusIconHeader {
  font-size: 19px;
  line-height: 19px;
}

.dataStatusIcon canvas {
  position: absolute;
  top: 0;
  left: -1px;
}

.change-icon > .dataStatusIcon + .dataStatusIcon,
.change-icon:hover > .dataStatusIcon {
  display: none;
}

.change-icon:hover > .dataStatusIcon + .dataStatusIcon {
  display: inline-block;
}

/*
 * This class informs the screenshot service to size the screenshot to the content that is decorated with this class.
 * Seeq display pane views that do not scale to fill the display pane should place this class on the element that
 * immediately contains the view content. A view that uses this class should also set the screenshotSizeToContent
 * optional boolean view property to true (see optional properties description in worksheet.module.js).
 */
.screenshotSizeToContent {
}

sq-new-workbench-item {
  .h4 {
    text-align: left;
  }

  .btn {
    border: none;
  }

  .btn div {
    white-space: pre-wrap;
    text-align: left;
  }
}

.large-color-swatch {
  position: relative;
}

.colorswatch-overlay {
  position: absolute;
  bottom: -4px;
  right: -2px;
  z-index: 9000000;
  /**
  * pointer-events none to enable the click-through on the gear
  */
  pointer-events: none;
}

.color-rgb-input {
  max-width: 50px;
}

.color-hex-input {
  max-width: 155px;
}

.picker-close {
  position: absolute;
  right: 10px;
  top: 10px;
  pointer-events: auto;
}

#colorPickerContainer {
  hr {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.loginFooter {
  position: absolute;
  bottom: 0;
}

sq-select-item {
  .textEntryInput {
    min-height: 32px;
  }
}

.folderExplorerRow {
  &:hover {
    cursor: pointer;
  }
}

// this is used to correct the jumping of the icons when using fa-spin
// https://jsfiddle.net/Dhanck/9t6y85jx/2/ - issue here: https://github.com/FortAwesome/Font-Awesome/issues/671#issuecomment-338336059
.correctSpin {
  line-height: 15px;
  height: 14px;
}

.annotationsCloseBtn {
  height: 20px;
  line-height: 4px;
  margin-bottom: 2px;
}

.autoUpdateControl {
  position: absolute;
  transform: translate(255px, -30px);
}

.workbookIcon {
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 3px;
  padding-top: 3px;
}

.workbookIconLarge {
  width: 32px;
  height: 32px;
  border-radius: 3px;
  padding-top: 4px;
  text-align: center;
  font-size: 1.8em;
}

/**
The following CSS replaces standard radio buttons and checkboxes with the corresponding FontAwesome icons.
The approach is described here:
https://weblog.west-wind.com/posts/2015/Feb/26/Using-FontAwesome-Fonts-for-HTML-Radio-Buttons-and-Checkboxes

To ensure your checkboxes and radio buttons are rendered completely ensure that the <label> tag is placed after the
checkbox/radio button tag and that the label has a for attribute that matches the radio button id.

Example:
 <input type="radio" id="prettyRadioButton"/>
 <label for="prettyRadioButton">My Wonderful Radio Button</label>
 */
.radio label, .checkbox label {
  padding: 1px;
}

.radio {
  margin-top: 0px !important;
}

/* hide the OS/browser checkboxes/radio buttons */
input[type=checkbox], input[type=radio] {
  opacity: 0;
  margin: 0;
  width: 0 !important;
}

input[type=checkbox] + label:hover, input[type=radio] + label:hover {
  cursor: pointer;
}

/* set FontAwesome and positioning */
input[type=checkbox] + label:before, input[type=radio] + label:before {
  @extend .fa-fw;
  font-family: "FontAwesome";
  display: inline-block;
  padding: 2px 1px 2px 1px;
  margin-right: 3px;
  /* this is a transparent outline to avoid flicker when focused */
  outline: rgba($white, 0) solid 1px;
}

/* set checkbox icons and opacities for normal and checked */
input[type=checkbox] + label:before {
  content: "\f096";
}

input[type=checkbox]:checked + label:before {
  content: "\f14a";
}

input[type=checkbox]:checked + label:hover:before, input[type=radio]:checked + label:hover:before {
  filter: alpha(opacity=100);
  opacity: 1;
}

/* set radio button icons and opacities for normal and checked */
input[type=radio] + label:before {
  content: "\f1db";
}

input[type=radio]:checked + label:before {
  content: "\f058";
}

input[type=radio]:disabled + label:before {
  color: $sq-disabled-gray;
}

/** End custom radio button/checkbox css **/

a {
  text-decoration: underline;
}

.nav-tabs > li > a, .nav-tabs > li.active > a {
  text-decoration: none;
}

.dropdown-menu > li > a {
  text-decoration: none;
}

.dropdown-menu > .popover-body > a, .dropdown-menu > .popover-body > div > a {
  text-decoration: none;
}

.btn-toolbar {
  text-decoration: none;
}

.thresholdTool {
  .priorityColor {
    padding: 0;
    border-width: 0;

    .colorPickerSwatch {
      border-radius: 0;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      border-color: $sq-dark-gray
    }
  }

  .thresholdEntry {
    margin-bottom: 0px;
  }

  .thresholdEntryWrapper {
    .separatorDash {
      border-top: 2px solid $sq-dark-primary-analysis;
      width: 8px;
      margin-left: 2px;
      margin-right: 2px;
    }
  }
}

@mixin animatedHeight($duration: 0.25s, $maxHeight: 50px) {
  &.ng-enter {
    transition: $duration all linear;
    max-height: 0;
    opacity: 0;

    &.ng-enter-active {
      max-height: $maxHeight;
      opacity: 1;
    }
  }
  &.ng-leave {
    transition: $duration all linear;
    max-height: $maxHeight;
    opacity: 1;

    &.ng-leave-active {
      max-height: 0;
      opacity: 0;
    }
  }
}

.animatedThreshold {
  @include animatedHeight(0.25s, 35px);
}

.positionRelative {
  position: relative;
}

.positionAbsoluteFill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.priorityPopover {
  min-width: 200px;

  .picker-close {
    top: 7px;
  }

  .selectedOverlay {
    @extend .positionAbsoluteFill;
    opacity: 0.92;
    cursor: auto;
    background: $sq-overlay-gray;
  }

  .popover-title {
    font-size: 13px;
    font-weight: normal;
    text-align: left;
  }
}

.borderStyleSolid {
  border-style: solid;
}

.borderColorGray {
  border-color: #ccc;
}

.leftBracket {
  position: relative;
  padding: 0.25em;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0.5em;
    height: 100%;
    border: 0.15em solid $sq-link-color-analysis;
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

sq-composite-search {
  .outputMaximumDuration {
    .outputMaximumDurationField {
      flex-grow: 2;
    }

    .removeButton {
      padding: 0.1em 0.5em;
      font-size: 1.5em;
      line-height: 1.7em;
    }
  }

  .swapButton {
    padding: 5px 3px;
    margin-left: -5px;

    // Fixes an IE11 jump/alignment issue on hover
    outline: 0;
  }
}

// set the z-index so that the right border doesn't go missing when in error state (https://github.com/twbs/bootstrap/issues/17001)
.input-group .form-control {
  z-index: 3;
}

.excelExportInput {
  border-width: 1px;
  border-radius: 3px;
  border-style: solid;
  padding: 4px;
}

sq-acl-status .aclStatusWrapper {
  padding: 3px;
  background-color: $sq-lightest-gray;

  & > div {
    margin-left: 5px;
  }
}

.disabledLook {
  opacity: 0.44;
}

.disabledBehavior {
  pointer-events: none;
  cursor: inherit;
}

.disabledTextBoxGrey {
  opacity: 0.65;
  cursor: not-allowed;
  pointer-events: none;
  background-color: #eeeeee;
}

.disabledIconSelect {
  opacity: 0.65;
  cursor: not-allowed;
  pointer-events: none;
}

.bulkEditModal {
  width: 90% !important;
  max-width: none !important;
}

.aclModalWindow .modal-dialog {
  max-width: 710px !important;
  min-width: 700px !important;
  width: 710px !important;
  min-height: 300px;
  max-height: 700px;
}

.fakeTableRow {
  line-height: 1.5;
  border-bottom: 1px solid #ddd;
}

.fakeTableRowDivider {
  line-height: 1.5;
  border-top: 1px solid #ddd;
  border-bottom: 3px solid #ddd;
}

.fakeTableHeader {
  font-weight: 600;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}

.fakeTableWrapper {
  max-height: 220px;
  overflow-y: auto;
}

.odd {
  background-color: #f9f9f9;
}

.fakeTable .flexRowContainer:nth-of-type(odd) {
  background-color: #f9f9f9;
}

#aclGrid {
  max-height: 100%;
}

// The uib-dropdown dropdown menu sometimes does not render correctly in IE 11. The workaround is to make the dropdown
// .dropdown-append-to-body and then apply the .modalDropdown class to dropdown menu. This ensures a high enough
// z-index is set on the dropdown so it will render above the modal.
body > .dropdown-menu.modalDropdown {
  overflow-y: auto;
  max-height: 250px;
  z-index: $zindex-modal;
}

// The uib-typeahead dropdown menu sometimes does not render correctly in IE 11. The workaround is to make the dropdown
// .dropdown-append-to-body="true" and then raise the z-index of the dropdown so it appears on top of the modal.
// Unfortunately, there is no way to append a class to the dropdown so we're forced to use the template-url name to
// distinguish the typeahead dropdown we want to appear on top the modal.
body > .dropdown-menu[template-url="aclAutocompleteTemplate.html"] {
  overflow-y: auto;
  max-height: 250px;
  z-index: $zindex-modal;
}

/* Since we don't have a lock icon for data lab, just use the icon we do have. */
.fc-seeq-datalab-lock:before {
  content: "\e90e";
}

.react-select-menuList,
.react-select-small-menuList {
  background-color: white;
  border: solid 1px #ccc;
  min-width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.react-select__menu, .react-multiple-select__menu,
.react-select-small__menu, .react-multiple-select-small__menu {
  margin-top: 0 !important;
}

.react-select__menu-list,
.react-multiple-select__menu-list,
.react-select-small__menu-list,
.react-multiple-select-small__menu-list {
  padding-top: 0 !important;
}

.react-select__menu-list {
  .react-select__group {
    padding-bottom: 0 !important;
    padding-top: 3px !important;
  }

  .react-select__group:first-child {
    padding-top: 0 !important;
  }
}

.react-select-small__menu-list {
  .react-select-small__group {
    padding-bottom: 0 !important;
    padding-top: 0px !important;
  }

  .react-select-small__group:first-child {
    padding-top: 0 !important;
  }
}

.react-select__group-heading, .react-multiple-select__group-heading {
  text-transform: none !important;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px !important;
}

.react-select-small__group-heading {
  text-transform: none !important;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 10px !important;
}

.react-multiple-select__indicators {
  display: none !important;
}

.react-select__indicator {
  padding: 2px !important;
}

.react-select-small__indicator {
  padding: 0px !important;
}

.react-select__value-container {
  flex-wrap: nowrap !important;
}

.react-select-small__value-container {
  flex-wrap: nowrap !important;
  padding: 0px 2px !important;
  height: 20px !important;
}

.react-multiple-select__multi-value {
  background-color: transparent !important;
  width: 100%;
}

.reactSelectWrapper {
  width: 100%;
}

.react-multiple-select__multi-value {
  padding: 3px;
}

.react-select-text-entry__indicators {
  height: 32px !important;
  width: 30px !important;
}

.react-select-small-text-entry__indicators {
  height: 20px !important;
}

.react-select__indicators {
  min-height: 32px !important;
}

.react-select-small__indicators {
  min-height: 20px !important;
}

.react-multiple-select__control {
  box-shadow: none !important;

  &:focus, &:active, &:hover {
    outline: 0 !important;
    box-shadow: none !important;
  }
}

.react-select-text-entry__control {
  box-shadow: none !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  min-height: 32px !important;

  &:focus, &:active, &:hover {
    outline: 0 !important;
    box-shadow: none !important;
    min-height: 32px !important;
  }
}

.react-select-small-text-entry__control {
  box-shadow: none !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  min-height: 20px !important;

  &:focus, &:active, &:hover {
    outline: 0 !important;
    box-shadow: none !important;
    min-height: 20px !important;
  }
}

.react-select-text-entry__value-container,
.react-select-small-text-entry__value-container {
  flex-wrap: nowrap !important;

  .itemAsset {
    font-style: italic;
    direction: rtl; // So that leaf node of long asset paths is shown
  }
}

.react-select__control, .react-multiple-select__control {
  box-shadow: none !important;
  min-height: 32px !important;

  &:focus, &:active, &:hover {
    outline: 0 !important;
    box-shadow: none !important;
    min-height: 32px !important;
  }
}

.react-select-small__control {
  box-shadow: none !important;
  min-height: 20px !important;

  &:focus, &:active, &:hover {
    outline: 0 !important;
    box-shadow: none !important;
    min-height: 20px !important;
  }
}

.react-select__menu,
.react-select-small__menu {
  z-index: 10 !important; // So it shows above btn-group elements
}

.react-select .react-select__single-value,
.react-select-small .react-select-small__single-value {
  line-height: normal;
  overflow: visible;
}

.react-select-small__option {
  padding: 3px 12px !important;
}

.react-select-small__indicator-separator {
  display: none;
}

.small-select.react-select__control, .small-select.react-select__indicators {
  max-height: 20px !important;
  min-height: 20px !important;
  height: 20px !important;
}

.small-select .small-select.react-select__single-value {
  line-height: 1.2;
  margin: 0 !important;
}

.small-select {
  font-size: 11px;
}

.small-select.react-select__menu, .small-select.react-select__menu-portal {
  z-index: 1000 !important;
}

.paginator {
  .pagination {
    margin: 5px 0px;
  }
}

.sampleDisplayOptionColumn, .dashStyleColumn {
  .dropdown-toggle::after {
    display: none !important;
  }
}

.tableHeaderResizer {
  position: absolute;
  top: 0;
  right: -5px;
  bottom: 0;
  cursor: col-resize;
  width: 10px;
  z-index: 1;
}

#predictionModel {
  .disabled {
    color: #cccccc !important;
  }
}

#predictionModelTable {
  table {
    border: 1px solid #dee2e6;
  }

  th {
    color: #007960;
  }

  th, td {
    padding: 2px 7px;
  }

  th:not(:last-child), td:not(:last-child) {
    border-right: 1px solid #dee2e6;
  }

  .tableHeaderResizer {
    right: -13px;
  }

  i {
    color: #007960;
  }
}

.width-99-percent {
  width: 99%;
}

.value-with-units.react-select__control {
  border-color: #cccccc;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0;
  height: 30px;

  &:hover {
    border-left-width: 1px;
  }
}

.value-with-units-value-input {
  height: 32px !important;
  width: 70px;
  text-align: right;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.reportAutoUpdateTimeStrategy {
  line-height: 32px;
}

.reportAutoUpdateTimezone {
  line-height: 32px;
}

#weeklyScheduleConfig {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 10px;

  .form-check {
    margin: 0;
  }
}

.reportConfigDateRangeHeader {
  margin: -5px -4px -1px;
}

.fixedValueWithUnit {
  .disabled {
    color: #333333;
    background-color: #eeeeee;
    cursor: not-allowed;

    span {
      color: #333333;
    }
  }
}

#thresholdInputsFormGroup {
  .reactSelectWrapper {
    margin-right: -20px
  }

  transform: translateY(20px);
}

.valueSearchThreshold {
  .reactSelectWrapper {
    padding: 0;
    border-radius: 0;
  }

  .textEntryInput {
    border-width: 1px;
  }
}

.formRow [class*="__value-container--has-value"] {
  width: 0;
}

.simpleSaveForm [data-testid$="Label"] {
  font-weight: bold;
}

.textEntryInput {
  outline: none;
}

.textEntryAllowed {
  align-items: stretch;

  button {
    height: 100%;
  }

  input, .react-select-text-entry__control {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

.viewSelectorSvg {
  width: 19px;
  height: 19px;
  vertical-align: middle;
  fill: white;
}

.viewSelectorSvgDropdown {
  margin-right: 10px;
  fill: black;
}

.pluginToolSvg {
  width: 19px;
  height: 19px;
  vertical-align: middle;
}

.pluginContainer {
  z-index: 1;
  border: 0;
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.disabledTableFilter {
  border-color: #e2e2e2;
  background-color: #f6f6f6;
  cursor: not-allowed;
}

.width-maximum.form-control.disabledTableFilter:hover {
  border-color: #e2e2e2 !important;
}

//box shadow creates unsightly artifacts on some components
.error-border {
  box-shadow: none !important;
}

.documentContent.fixedWidth:not(.ckEditorMain) {
  width: 800px !important;

  @include media-breakpoint-up(md) {
    align-self: center;
  }
}

.buttonDisabledTooltip {
  .btn.disabled, .btn[disabled] {
    pointer-events: none;
  }

  display: inline-block;
  cursor: not-allowed;
}

.sampleInput {
  border-radius: 4px;
}

.removableBlockWrapper {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #cccccc;
  position: relative;

  .closeIconWrapper {
    position: absolute;
    right: 10px;
  }
}

.homeScreenWelcomeTile {
  border-radius: 4px;
  padding: 10px;
}

.welcomeActions {
  top: 10px;
  right: 10px;
}

.logTracker {
  .logTrackerNavigationButtons {
    position: absolute;
    right: 15px;
    margin-top: 10px;
  }

  .logFiltersContainer {
    background-color: $sq-light-gray;
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
  }
}

.piVisionBorder {
  padding: 13px;
  background-color: $sq-overlay-gray;
  border: 1px solid $sq-disabled-gray;
  border-radius: 3px;

  ul {
    padding-left: 1.7em;
  }
}

.reportDateModal {
  .sq-checkbox {
    label {
      font-weight: 600 !important;
      display: inline-flex;
    }
  }

  .displayRangeSelector {
    label {
      font-size: 13px;
    }
  }

  .spec_reportDateModal_offset.react-select__control {
    height: 32px;
  }
}

.noArrow .arrow {
  display: none;
}

.width-fit-content {
  width: fit-content;
}

.highlight-search {
  background-color: lighten($backgroundcolor-hover, 10%);
  padding: 0;
}

.noLeftRadius {
  border-start-start-radius: 0 !important;
  border-end-start-radius: 0 !important;
}

.noRightRadius {
  border-start-end-radius: 0 !important;
  border-end-end-radius: 0 !important;
}

#propertiesFormGroup .removableBlockWrapper {
  flex: 1;
}

.xyPlotIcon {
  height: 10px;
  line-height: 10px;
  font-size: 10px;
}

.displayRangeIcon {
  line-height: 14px !important;
  font-size: 14px !important;
}

.colorModeButtonItem {
  padding: 2.5px 12px;
  cursor: pointer;
}

.colorModeButtonItem:hover {
  background-color: $sq-link-color-analysis;
  color: white;
}

.highlightFade {
  animation: themeColorFade 3s linear;
}

.legend-wrapper {
  max-height: 200px;
  overflow-y: auto;
}

#selectAssetFormGroup .form-control {
  height: fit-content;
  min-height: 34px;

  &, &:hover, &:focus {
    border-color: $sq-darkish-gray;
  }
}

.color_analysis .searchWidget .heading {
  color: #007960;
  border-bottom: 1.5px solid #999;
  padding: 15px 0 3px 0;
  cursor: pointer;
}

.homeScreenToolCard {
  color: $sq-link-color-topic;
  fill: $sq-link-color-topic;
  padding: 10px;
  border: 1px solid grey;
  border-radius: 5px;
  word-break: break-word;
  min-width: 310px;
  max-width: 310px;
  min-height: 82px;
  margin: 4px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 1px 1px 3px 1px;
}

.addOnFilterClear {
  align-self: center;
  padding: 7px;
  cursor: pointer;
}

.pluginLoadingSpinnerWrapper {
  position: relative;
  vertical-align: middle;
  z-index: 1000;
  height: 100%;
  width: 100%;
}

.pluginLoadingSpinner {
  position: relative;
  top: 50%;
  left: 50%;
}

.addOnSeparator {
  margin-right: 10px;
}

.treemapToolbar {
  .react-select__indicators {
    min-height: 26px !important;
  }

  .react-select__control {
    min-height: 26px !important;
  }
}

.beamerTrigger {
  position: absolute;
  bottom: 35px;
  right: 8px;
  z-index: 6000;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background-color: #03a9f4;
}

#investigateToolbar {
  .searchBreadcrumbs {
    margin-bottom: 2px;
  }
}
