/** Match our Seeq color scheme **/
:root {
  --toastify-color-info: #5bc0de;
  --toastify-color-success: #5cb85c;
  --toastify-color-warning: #f0ad4e;
  --toastify-color-error: #d9534f;
  --toastify-font-family: inherit;
}

/** Override the cursor (defaults to pointer) because we don't close on click **/
.Toastify__toast {
  cursor: default;
}

/** These are used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
  top: 44px;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 44px;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 44px;
  right: 1em;
}

/** when running system tests we want to disable animation to ensure notifications can be interacted with **/
@keyframes Toastify_systemTestNoAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 1
  }
}

.Toastify_systemTest {
  animation-name: Toastify_systemTestNoAnimation;
}
