/* source-sans-pro-regular - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Sans Pro'), local('SourceSansPro-Regular'),
  url('../fonts/source-sans-pro-v9-latin_latin-ext-regular.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/source-sans-pro-v9-latin_latin-ext-regular.woff') format('woff'); /* IE11 */
}

/* source-sans-pro-italic - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: local('Source Sans Pro Italic'), local('SourceSansPro-It'),
  url('../fonts/source-sans-pro-v9-latin_latin-ext-italic.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/source-sans-pro-v9-latin_latin-ext-italic.woff') format('woff'); /* IE11 */
}

/* source-sans-pro-600 - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
  url('../fonts/source-sans-pro-v9-latin_latin-ext-600.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/source-sans-pro-v9-latin_latin-ext-600.woff') format('woff'); /* IE11 */
}

/* source-sans-pro-600italic - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldIt'),
  url('../fonts/source-sans-pro-v9-latin_latin-ext-600italic.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/source-sans-pro-v9-latin_latin-ext-600italic.woff') format('woff'); /* IE11 */
}

/* source-sans-pro-700 - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
  url('../fonts/source-sans-pro-v9-latin_latin-ext-700.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/source-sans-pro-v9-latin_latin-ext-700.woff') format('woff'); /* IE11 */
}

/* source-sans-pro-700italic - latin_latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldIt'),
  url('../fonts/source-sans-pro-v9-latin_latin-ext-700italic.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/source-sans-pro-v9-latin_latin-ext-700italic.woff') format('woff'); /* IE11 */
}
