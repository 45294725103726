.formula-type {
  font: normal normal normal 14px/1 fontCustom;
  font-family: fontCustom !important;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-left: 3px;
  padding-right: 3px;
  color: #606061; // $sq-fairly-dark-gray
}

.formula-type-signal:before {
  @extend .formula-type;
  @extend .fc-series;
}

.formula-type-condition:before {
  @extend .formula-type;
  @extend .fc-capsule-set;
}

.formula-type-capsule:before {
  @extend .formula-type;
  @extend .fc-capsule;
}
