/**
  Topic Theme - Seeq customizations for the Froala editor.
**/

$theme: 'topic';
$ui-color: $sq-link-color-topic;
$ui-border-color: $sq-dark-primary-topic;

@import 'seeq-theme';

.topic-theme {
  .fr-wrapper .fr-element.fr-view {
    padding: 16px;
  }
}
