.reactJsonContent {
  min-width: 200px;

  &.tableVisualization {
    min-width: auto;
  }
}

.contentWrapper .loadingSpinner .reactJsonContent {
  opacity: 50%;
}