.customOverlay {
  &.tableViewer {
    background: #FFFFFF;
    border-radius: 5px;
    top: 10px;
  }

  .settingsButton {
    cursor: pointer;
    margin-left: 10px;
  }

  .noMouse {
    pointer-events: none;
  }
}
