/* Re-usable HTML/CSS components, akin to Bootstrap 4 components */
/* This file was created in an effort to clean up our component CSS as we convert to React */
/* All customizations for React Bootstrap should be added here */

/** DROPDOWNS **/
.dropdown-menu > a.dropdown-item {
  text-decoration: none;
}

.dropdown-toggle::after {
  display: none;
}

.dropdownCaret > .dropdown-toggle::after {
  display: inline-block;
}

// align the submenu arrow icon to the right
.dropright .dropdown-toggle::after, .dropright .dropdown-toggle-menu-item::after {
  float: right;
  margin-top: 5px;
}

.textPrimaryMenuItem {
  &.dropright > a {
    text-decoration: none;
  }

  text-decoration: none;
}

// Popover with dropdown which has the same style as a dropdown menu
.popoverDropdown {
  border-radius: 4px;
  padding: 5px 0;
}

.popoverDropdown .dropdown-item.disabled {
  color: $sq-disabled-gray !important;
}

/** BUTTONS **/
.sq-btn {
  padding: 5px 10px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;

  &.muted {
    opacity: 0.8;
  }

  &, &:active, &:focus, &:hover, &:active:focus, &:active:hover {
    box-shadow: none !important;
    outline: none !important;
  }
}

.sq-btn-dropdown {
  button {
    padding: 0;

    &, &:hover, &:active, &:focus {
      background-color: transparent !important;
      border: none;
      outline: none;
    }
  }
}

.sq-btn.disabled, .sq-btn[disabled], fieldset[disabled] .sq-btn {
  opacity: 0.45;
  cursor: not-allowed;
}

.sq-btn-group {
  .sq-btn {
    &:not(:last-child):not(.dropdown-toggle) {
      @extend .noRightRadius;
    }

    &:not(:first-child) {
      @extend .noLeftRadius;
      margin-left: -1px;
    }
  }
}

.activeInnerShadow {
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%) !important;
}

.input-group {
  .sq-btn {
    &:not(:last-child):not(.dropdown-toggle) {
      @extend .noRightRadius;
    }

    @extend .noLeftRadius;
    margin-left: -1px;
  }
}

.sq-btn-sm {
  font-size: 12px;
}

.sq-btn-xs {
  padding: 1px 5px !important;
  line-height: 1.5;
}

.workbenchBtn.show {
  display: inline-block !important;
}

.workbenchBtn > button {
  padding: 6px 12px;
}

button {
  &, &:hover, &:active, &:focus {
    box-shadow: none !important;
  }
}

button.sq-btn-toolbar, button.sq-btn-toolbar-dropdown, .sq-btn-toolbar-dropdown > .sq-btn {
  height: 36px;

  &, &:hover, &:active, &:focus {
    background-color: transparent !important;
    border: none;
    outline: none;
  }

  &:active, &.active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  }

  small {
    font-size: 10px;
  }
}

.sq-btn-toolbar-dropdown {
  padding: 0;
}

.sq-btn-toolbar-dropdown.active {
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%) !important;
  border-radius: 3px;
}

.sq-btn-toolbar-sm, .sq-btn-toolbar-dropdown-sm, .sq-btn-toolbar-dropdown-sm > .sq-btn {
  padding: 1px 5px;
}

/** MODALS **/
.modal-header .close {
  font-size: 20px !important;
}

// Default modal size in Bootstrap 4 changed from 600px to 500px
// If you have a modal that is not a default size and shouldn't be 600px, make sure to use !important on those sizes
.modal-dialog:not(.modal-sm, .modal-lg, .modal-xl) {
  min-width: 600px;
  max-width: 600px;
}

// Only needed until we remove bootstrap 3
.modal-xl {
  width: auto !important;
}

/** INPUTS **/
.input-sm, .input-group-sm {
  height: 30px !important;
  font-size: 12px;
}

.searchTitleInput > input {
  &, &:hover, &:active, &:focus {
    box-shadow: none !important;
  }
}

.browseColumn .nav-item.active {
  margin: 0 !important;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__menu .react-select__option {
  cursor: pointer;
}

.sq-checkbox {
  padding-left: 0;

  label {
    font-weight: normal;
    display: flex;
    align-items: baseline;
  }
}

.operator-select.react-select__control {
  width: 50px;
}

.operator-select.react-select__menu {
  width: 225px;
}

.sq-alert-danger, .sq-alert-warning {
  opacity: 1 !important;

  .close {
    padding: 0;
    opacity: 0.5;
  }
}

.sq-alert-success {
  opacity: 1 !important;

  .alert-link {
    font-weight: normal;
  }
}

.slider-no-padding .form-control {
  padding: 0 !important;
  border: none;
}

.sq-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 4px;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;

  &, &:hover, &:focus, &:active {
    opacity: 1;
    background: $sq-dark-gray;

  }
}

.sq-slider::-moz-range-progress {
  height: 5px;
}

.sq-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: pointer;
}

.worksheetMenuDropdown {
  .dropdown-menu {
    width: 120px;
    min-width: 120px;
  }
}

/** TABLES **/

// thick border on the first column instead of the first row
.table-bordered-first-column {
  @extend .table-bordered;

  thead {
    tr {
      td {
        border-bottom-width: 1px;
      }
    }
  }

  td:first-child {
    border-right-width: 2px;
  }
}

.tourCarousel {
  .carousel-control-next, .carousel-control-prev {
    text-decoration: none !important;
    opacity: 1;
  }

  .carousel-control-prev {
    left: -75px;
  }

  .carousel-control-next {
    right: -75px;
  }

  .carousel-indicators {
    bottom: -35px;
  }
}

.form-check-input:disabled ~ .form-check-label {
  cursor: not-allowed !important;
}

.CircularProgressbar-text {
  font-size: 50px !important;
}

.nav {
  font-size: 13px;

  & > a {
    padding: 10px 15px;
    border-width: 1px;
    margin-bottom: 0;
    text-decoration: none;
  }

  &.active {
    & > a {
      &, &:hover, &:focus {
        border-width: 0;
        outline: 0;
      }
    }
  }

  &:not(.disabled):not(.active) {
    & > a {
      &:hover, &:focus {
        outline: 0;
      }
    }
  }
}

.tab-content, .tab-pane {
  height: 100% !important;
}

.modal-header, .modal-body {
  padding: 15px 25px !important;
}

.modal-content {
  border-radius: 6px !important;
  box-shadow: rgba(0, 0, 0, 0.5) 0 5px 15px 0;
}

.progress {
  font-size: 13px;
  height: 18px;
  line-height: 18px;
}

// Boostrap 4 uses rems and it makes our font-size super small so we are manually setting it here for the components
// we use until we are ready to develop a better solution
.popover, .dropdown-menu, .btn {
  font-size: 13px;
}

.popover.large {
  max-width: 600px !important;
}

.popover.medium {
  max-width: 235px !important;
  width: 235px !important;
}

.pagination-small {
  margin: 2px 0px !important;

  li > a,
  li > span,
  {
    padding: 1px 6px;
  }
}

.pagination {
  .page-item {
    .page-link {
      text-decoration: none;

      &:not(.pagination-small) {
        font-size: 13px !important;
      }
    }
  }
}

.form-control:not(textarea):not(.input-xs) {
  height: 34px;
}

.breadcrumb-item:not(:first-child)::before {
  content: $breadcrumb-separator !important;
}

// From bootstrap/_component-animations.scss
.collapse.in {
  display: block;
}
