/**
  Base Seeq customizations for the Froala editor.
**/

.fr-element {
  img {
    padding: 0px;
  }
}

.#{$theme}-theme {
  .fr-wrapper .fr-element.fr-view {
    font-family: inherit;
    font-size: 13px; // attempting to match Froala v2
    line-height: 1.42857; // attempting to match Froala v2
    padding: 10px;
  }

  &.fr-toolbar {
    &.fr-top {
      // disable the thick border and rounded corners on the top of the editor
      border-top: 0;
      border-radius: 0px;
    }

    .fr-btn-grp {
      border-right: 1px solid #ebebeb;
    }

    .fr-command.fr-btn {
      height: 24px;
      margin-left: 0px;
      margin-right: 0px;

      &.fr-active svg.fr-svg path {
        fill: $ui-color;
      }

      i, svg.fr-svg {
        height: 22px;
        margin: 2px 4px;
      }

      &[data-cmd="insertSeeqContent"], &[data-cmd="journalLink"], &[data-cmd="refreshImage"], &[data-cmd="bulkEditContent"] {
        background-color: $sq-link-color-analysis; // always use analysis color
        color: white;
        margin-left: 2px;
        margin-right: 2px;
      }

      .fc {
        padding-top: 2px;
        margin: 3px 2px;
      }
    }

    .fr-btn-grp {
      margin: 0px;
    }

    transition: none;

    &.fr-toolbar-open {
      height: 37px;
      padding-bottom: 67px;
    }

    .fr-more-toolbar {
      transition: none;

      &.fr-expanded {
        height: 36px;
      }
    }
  }

  &.fr-popup {
    .fr-command.fr-btn {
      height: 30px;
      margin-left: 2px;
      margin-right: 2px;

      &[data-cmd="updateSeeqContent"] {
        background-color: $ui-color;
        color: white;
      }

      i, svg.fr-svg {
        margin-top: 4px;
        margin-bottom: 4px;
        width: 20px;
      }
    }
  }
}
